import React, { useCallback, useRef } from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Link, navigate } from "gatsby"
import { Alert, Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"

import * as css from "./features.module.css"
import * as globalCss from '../components/styles/global.module.css'
import ReactTooltip from "react-tooltip"
import { faCheck, faCheckCircle, faCircle, faCircleNotch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import log from "../components/log"
import axios from "../api/axios"
import LoginButton, { getEmailFromLocalStorage } from "../components/auth/LoginButton"
import { render } from "react-dom"
import acceptedPayment from "../assets/images/accepted_payment_method.webp"
import acceptedPaymentQrisOnly from "../assets/images/accepted_payment_method_qris_only.webp"

import metaPreviewImage from "../assets/images/thumbnails/thumbnail_index.jpg"
import MetaTags from "../components/MetaTags"
import FrequentQuestions from "../components/homepage/FrequentQuestions"
import useAuth from "../hooks/useAuth"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import style from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark"

const isDebug = (process.env.APP_DEBUG == 'true')

let createPaymentWindow = async (email: string, packageId: string) => {
    // Prepare
    let endpoint = process.env.APP_API_ENDPOINT


    // Call the API
    try {
        const response = await axios.post(endpoint + "/api/checkout/plan", {
            email: email,
            plan: packageId,
        })

        return {
            code: response?.status,
            message: response?.data?.message,
            data: response?.data?.data,
        }
    } catch (err: any) {
        if (err?.response == undefined) {
            log("error", "Gagal saat membuat jendela pembayaran.", {
                exception: err,
            })

            return {
                code: 408,
                message: "Tidak dapat menghubungi server, coba lagi.",
                data: null,
            }
        }

        return {
            code: err?.response?.status,
            message: err?.response?.data?.message,
            data: null,
        }
    }
}

// markup
const Features = () => {

    return (
        <>
            <MetaTags
                metaDescription="Pilihan Fitur"
                metaTitle="Pilihan Fitur"
                metaPath="/features"
                metaPreviewImage={metaPreviewImage}
            />
            <Header />

            <Container className={css.section}>
                <Row>
                    <Col>
                        <h1>Pilihan Fitur</h1>
                        <p>Pilih paket yang sesuai dengan fitur yang kamu butuhkan</p>


                        <UpgradeProContent />
                        {/* 
                        <div className={css.themes}>
                            <div className={css.title}>Saat ini tersedia {themes.length} pilihan tema! 🖌️</div>
                            <div className={css.titleSub}>Dan terus akan bertambah, kamu juga bisa request tema. 😉</div>

                            <Row>
                                {themes.map((item, index) => {
                                    return (
                                        <>
                                            <Col>
                                                <div className={css.sample}>
                                                    <img src={item.sample} alt={item.name} />
                                                </div>
                                                <div className={css.name}>Tema {item.name}</div>
                                            </Col>
                                        </>
                                    )
                                })}
                            </Row>
                        </div> */}
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export const UpgradeProContent = () => {
    const { isAuthenticated, isLoading } = useAuth()

    const [tooltipImageLoaded, setTooltipImageLoaded] = React.useState(false);

    const [selectedPackageIndex, setSelectedPackageIndex] = React.useState(1);
    const [selectedPackageId, setSelectedPackageId] = React.useState('pro_one_month');
    const [checkoutButtonLabel, setCheckoutButtonLabel] = React.useState(
        (isAuthenticated ? 'Beli Pro 1 Bulan Lagi 🔥' : 'Beli Pro 1 Bulan 🔥')
    );

    React.useEffect(() => {
        if (selectedPackageIndex == 0) {
            setCheckoutButtonLabel('Masuk')
            setSelectedPackageId('basic')
        } else if (selectedPackageIndex == 2) {
            if (isAuthenticated) {
                setCheckoutButtonLabel('Beli Pro 6 Bulan Lagi 👍')
            } else {
                setCheckoutButtonLabel('Beli Pro 6 Bulan 👍')
            }
            setSelectedPackageId('pro_six_month')
        } else {
            if (isAuthenticated) {
                setCheckoutButtonLabel('Beli Pro 1 Bulan Lagi 🔥')
            } else {
                setCheckoutButtonLabel('Beli Pro 1 Bulan 🔥')
            }
            setSelectedPackageId('pro_one_month')
        }
    }, [selectedPackageIndex])


    // Define State
    const [email, setEmail] = React.useState(getEmailFromLocalStorage)
    const [checkoutResponse, setCheckoutResponse] = React.useState(<></>)
    const [isCheckoutLoading, setIsCheckoutLoading] = React.useState(false)
    const [isCheckoutSuccess, setIsCheckoutSuccess] = React.useState(false)


    // Save email to localStorage when `email` changes
    React.useEffect(() => {
        if (typeof window !== 'undefined' && email !== null) {
            localStorage.setItem('last_email', email)
        }
    }, [email]);

    // Login Action
    function checkoutAction(e: any) {
        e.preventDefault()

        // Reject if email still empty 
        if (!email) {
            setCheckoutResponse(<Alert variant={"danger"} className={css.center}>Email harus diisi</Alert>)
            return
        }

        setIsCheckoutLoading(true)
        createPaymentWindow(email, selectedPackageId)
            .then(
                function (value) {
                    if (value?.code === 200) {
                        setIsCheckoutSuccess(true)
                        setCheckoutResponse(
                            <Alert variant={"success"} className={css.center}>
                                {value?.message}
                            </Alert>
                        )

                        // Open payment window
                        window.open(value?.data.payment_window_url)
                    } else {
                        setIsCheckoutSuccess(false)
                        setCheckoutResponse(<Alert variant={"danger"} className={css.center}>{value?.message}</Alert>)
                    }
                },
                function (error) {
                    setIsCheckoutSuccess(false)
                    setCheckoutResponse(<Alert variant={"danger"} className={css.center}>Error: {error}</Alert>)
                }
            )
            .finally(() => {
                setIsCheckoutLoading(false)
            })
    }


    return (
        <>
            <div className={css.packages}>
                <Row className={css.list}>
                    <Col className={(selectedPackageIndex == 0 ? `${css.free} ${css.colActive}` : `${css.free}`)}
                        onClick={() => setSelectedPackageIndex(0)}
                    >
                        <div className={css.packageTitle}>Basic</div>
                        <div className={css.packagePrice}>Gratis</div>
                        <div className={css.packageFeatures}>
                            <ul>
                                <li>
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                    <div
                                        className={css.content}
                                        data-tip="React-tooltip"
                                        data-for={"basicProfile"}
                                    >
                                        Profil Anabul
                                    </div>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                    <div
                                        className={css.content}
                                        data-tip="React-tooltip"
                                        data-for={"upToOne"}
                                    >
                                        Maksimal 1 KTP
                                    </div>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                    <div
                                        className={css.content}
                                        data-tip="React-tooltip"
                                        data-for={"limitedThemes"}
                                    >
                                        1 Pilihan Tema
                                    </div>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                    <div
                                        className={css.content}
                                        data-tip="React-tooltip"
                                        data-for={"limitedDownload"}
                                    >
                                        Unduh KTP Digital
                                    </div>
                                    <div className={css.additionalBadge}>+ IDR 2K/unduh</div>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                    <div
                                        className={css.content}
                                        data-tip="React-tooltip"
                                        data-for={"missingPetsFeatures"}
                                    >
                                        Fitur Anabul Hilang
                                    </div>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                    <div
                                        className={css.content}
                                        data-tip="React-tooltip"
                                        data-for={"connectWithTwitter"}
                                    >
                                        Terhubung ke Twitter
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col className={(selectedPackageIndex == 1 ? `${css.monthly} ${css.colActive}` : `${css.monthly}`)}
                        onClick={() => setSelectedPackageIndex(1)}>
                        <div className={css.ribbon}>Populer 🔥</div>
                        <div className={css.packageTitle}>Pro 1 Bulan</div>
                        <div className={css.packagePrice}>IDR 15K</div>
                        <div className={css.packageFeatures}>
                            <ul>
                                <li>
                                    <FontAwesomeIcon icon={faCheckCircle} className={css.verified} />
                                    <div
                                        className={css.content}
                                        data-tip="React-tooltip"
                                        data-for={"verifiedProfile"}
                                    >
                                        Profil Anabul Verified
                                    </div>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                    <div
                                        className={css.content}
                                        data-tip="React-tooltip"
                                        data-for={"upToFive"}
                                    >
                                        Hingga 5 KTP 🤩
                                    </div>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                    <div
                                        className={css.content}
                                        data-tip="React-tooltip"
                                        data-for={"unlockAllCardThemes"}
                                    >
                                        Akses ke semua tema 🤩
                                    </div>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                    <div
                                        className={css.content}
                                        data-tip="React-tooltip"
                                        data-for={"unlimitedDownload"}
                                    >
                                        Unduh KTP Digital
                                    </div>
                                    <div className={css.additionalBadge}>Tanpa Batas</div>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                    <div
                                        className={css.content}
                                        data-tip="React-tooltip"
                                        data-for={"missingPetsFeatures"}
                                    >
                                        Fitur Anabul Hilang
                                    </div>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                    <div
                                        className={css.content}
                                        data-tip="React-tooltip"
                                        data-for={"connectWithTwitter"}
                                    >
                                        Terhubung ke Twitter
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col className={(selectedPackageIndex == 2 ? `${css.semester} ${css.colActive}` : `${css.semester}`)}
                        onClick={() => setSelectedPackageIndex(2)}>
                        <div className={css.ribbon}>Hemat 👍</div>
                        <div className={css.packageTitle}>Pro 6 Bulan</div>
                        <div className={css.packagePrice}>
                            <div className={css.realPrice}>IDR 90.000</div>
                            IDR 60K
                            <div className={css.discount}>Diskon 33%!</div>
                        </div>
                        <div className={css.packageFeatures}>
                            <ul>
                                <li>
                                    <FontAwesomeIcon icon={faCheckCircle} className={css.verified} />
                                    <div
                                        className={css.content}
                                        data-tip="React-tooltip"
                                        data-for={"verifiedProfile"}
                                    >
                                        Profil Anabul Verified
                                    </div>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                    <div
                                        className={css.content}
                                        data-tip="React-tooltip"
                                        data-for={"upToTen"}
                                    >
                                        Hingga 10 KTP 🤩
                                    </div>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                    <div
                                        className={css.content}
                                        data-tip="React-tooltip"
                                        data-for={"unlockAllCardThemes"}
                                    >
                                        Akses ke semua tema 🤩
                                    </div>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                    <div
                                        className={css.content}
                                        data-tip="React-tooltip"
                                        data-for={"unlimitedDownload"}
                                    >
                                        Unduh KTP Digital
                                    </div>
                                    <div className={css.additionalBadge}>Tanpa Batas</div>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                    <div
                                        className={css.content}
                                        data-tip="React-tooltip"
                                        data-for={"missingPetsFeatures"}
                                    >
                                        Fitur Anabul Hilang
                                    </div>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                    <div
                                        className={css.content}
                                        data-tip="React-tooltip"
                                        data-for={"connectWithTwitter"}
                                    >
                                        Terhubung ke Twitter
                                    </div>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                    <div
                                        className={css.content}
                                        data-tip="React-tooltip"
                                        data-for={"customThemes"}
                                    >
                                        Request Tema 😎
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>

                {
                    selectedPackageIndex == 0 ? (
                        <div className={css.loginBtnContainer}>

                            <p className={css.smallText}>Tetap bisa upgrade kapan saja.</p>
                            {
                                isAuthenticated ? (
                                    <form
                                        className={css.checkoutBtn}
                                    >
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                required={true}
                                                placeholder="E-mail"
                                                aria-label="E-mail"
                                                type="email"
                                                disabled={true}
                                                className={css.inputEmail}
                                            />

                                            <Button
                                                variant="primary"
                                                disabled={true}
                                            >
                                                Kamu Sudah Masuk
                                            </Button>
                                        </InputGroup>

                                    </form>
                                ) : (
                                    <LoginButton setIsOtpModalOpen={() => { navigate('/') }} isOnLandingPage={false} />
                                )
                            }

                        </div>
                    ) : (

                        <Row className={css.checkout}>
                            <Col>
                                <p className={css.smallText}>Setelah pembayaran, paket langsung aktif untuk e-mail tersebut.</p>
                                <form
                                    className={css.checkoutBtn}
                                    onSubmit={event => {
                                        checkoutAction(event)
                                    }}
                                >
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            required={true}
                                            placeholder="E-mail"
                                            aria-label="E-mail"
                                            type="email"
                                            value={email}
                                            className={css.inputEmail}
                                            onChange={input => {
                                                setEmail(input.target.value)
                                            }}
                                        />

                                        {isCheckoutLoading ? (
                                            <>
                                                <Button
                                                    variant="primary"
                                                    onClick={(e: any) => {
                                                        checkoutAction(e)
                                                    }}
                                                >

                                                    <div className={globalCss.dotLoading}>Membuat Jendela Pembayaran</div>
                                                </Button>
                                            </>
                                        ) : null}
                                        {!isCheckoutLoading && isCheckoutSuccess == false ? (
                                            <>
                                                <Button
                                                    variant="primary"
                                                    onClick={event => {
                                                        checkoutAction(event)
                                                    }}
                                                >
                                                    {checkoutButtonLabel}
                                                </Button>
                                            </>
                                        ) : null}
                                        {!isCheckoutLoading && isCheckoutSuccess == true ? (
                                            <>
                                                <Button variant="primary" disabled={true}>
                                                    <FontAwesomeIcon icon={faCheck} /> Pembayaran Terbuka
                                                </Button>
                                            </>
                                        ) : null}
                                    </InputGroup>

                                    {checkoutResponse}


                                </form>
                            </Col>
                        </Row>
                    )
                }

                <img
                    src={selectedPackageIndex == 0 ? acceptedPaymentQrisOnly : acceptedPayment}
                    className={css.acceptedPayment}
                    alt="Pembayaran yang diterima"
                    data-selected={selectedPackageIndex == 0}
                />
                {/* 

            <ReactTooltip place="left" id={"donation"} >
                <DonateStraysAnimal />
            </ReactTooltip> */}

            </div >

            <FrequentQuestions />


            <ReactTooltip place="right" id={"verifiedProfile"} className={css.tooltip}>
                <p>Mendapat tanda centang biru.</p>
                <div className={css.image}>
                    <div data-is-image-loaded={tooltipImageLoaded}>
                        <FontAwesomeIcon icon={faCircleNotch} className={globalCss.spinningIcon} />
                    </div>
                    <LazyLoadImage
                        afterLoad={() => setTooltipImageLoaded(true)}
                        beforeLoad={() => setTooltipImageLoaded(false)}
                        data-is-image-loaded={tooltipImageLoaded}
                        alt={'Contoh Fitur'}
                        src={'https://bucket-prod.ktpanabul.com/assets/features-preview/verified_badge.webp'}
                    />
                </div>
            </ReactTooltip>

            <ReactTooltip place="right" id={"upToFive"} className={css.tooltip}>
                <p>Bisa mendaftarkan hingga 5 KTP.</p>
            </ReactTooltip>

            <ReactTooltip place="right" id={"upToTen"} className={css.tooltip}>
                <p>Bisa mendaftarkan hingga 10 KTP.</p>
            </ReactTooltip>

            <ReactTooltip place="right" id={"limitedDownload"} className={css.tooltip}>
                <p>Ada tambahan biaya IDR 2000 setiap unduh KTP digital.</p>
            </ReactTooltip>

            <ReactTooltip place="right" id={"unlimitedDownload"} className={css.tooltip}>
                <p>Bisa mengunduh KTP digital tanpa batas dan tidak repot harus membayar tiap kali mengunduh KTP.</p>
            </ReactTooltip>

            <ReactTooltip place="right" id={"customThemes"} className={css.tooltip}>
                <p>Bisa meminta 1 tema custom.</p>
            </ReactTooltip>

            <ReactTooltip place="right" id={"connectWithTwitter"} className={css.tooltip}>
                <p>Bisa menambahkan foto ke halaman profil Anabul dengan mention @KtpAnabul dan menyertakan hashtag handle anabul.</p>
                <div className={css.image}>
                    <div data-is-image-loaded={tooltipImageLoaded}>
                        <FontAwesomeIcon icon={faCircleNotch} className={globalCss.spinningIcon} />
                    </div>
                    <LazyLoadImage
                        afterLoad={() => setTooltipImageLoaded(true)}
                        beforeLoad={() => setTooltipImageLoaded(false)}
                        data-is-image-loaded={tooltipImageLoaded}
                        alt={'Contoh Fitur'}
                        src={'https://bucket-prod.ktpanabul.com/assets/features-preview/connect_with_twitter.webp'}
                    />
                </div>
            </ReactTooltip>

            <ReactTooltip place="right" id={"basicProfile"} className={css.tooltip}>
                <p>Profil anabul biasa.</p>
            </ReactTooltip>
            <ReactTooltip place="right" id={"upToOne"} className={css.tooltip}>
                <p>Hanya bisa menyimpan 1 KTP</p>
            </ReactTooltip>
            <ReactTooltip place="right" id={"limitedThemes"} className={css.tooltip}>
                <p>Hanya bisa menggunakan 1 tema.</p>
            </ReactTooltip>

            <ReactTooltip place="right" id={"unlockAllCardThemes"} className={css.tooltip}>
                <p>Bisa menggunakan semua tema.</p>
                <div className={css.image}>
                    <div data-is-image-loaded={tooltipImageLoaded}>
                        <FontAwesomeIcon icon={faCircleNotch} className={globalCss.spinningIcon} />
                    </div>
                    <LazyLoadImage
                        afterLoad={() => setTooltipImageLoaded(true)}
                        beforeLoad={() => setTooltipImageLoaded(false)}
                        data-is-image-loaded={tooltipImageLoaded}
                        alt={'Contoh Fitur'}
                        src={'https://bucket-prod.ktpanabul.com/assets/features-preview/all_card_themes.gif'}
                    />
                </div>
            </ReactTooltip>
            <ReactTooltip place="right" id={"missingPetsFeatures"} className={css.tooltip}>
                <p>Memungkinkan untuk memberi tanda jika anabul kamu hilang, sehingga orang-orang bisa segera menghubungimu jika menemukannya.</p>
                <div className={css.image}>
                    <div data-is-image-loaded={tooltipImageLoaded}>
                        <FontAwesomeIcon icon={faCircleNotch} className={globalCss.spinningIcon} />
                    </div>
                    <LazyLoadImage
                        afterLoad={() => setTooltipImageLoaded(true)}
                        beforeLoad={() => setTooltipImageLoaded(false)}
                        data-is-image-loaded={tooltipImageLoaded}
                        alt={'Contoh Fitur'}
                        src={'https://bucket-prod.ktpanabul.com/assets/features-preview/missing-pets-features.gif'}
                    />
                </div>
            </ReactTooltip>

        </>
    )
}

export default Features
